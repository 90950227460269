import axios, { AxiosResponse, ResponseType } from "axios";
import {COOKIES, getCookie} from "utils/cookies";
import * as AxiosLogger from 'axios-logger';
import {authToken, isStaff, sessionId} from 'utils/auth';
import { toast } from "react-toastify";
import deleteAllCookies from "../handlers/killCookies";
import {getCountryPreferenceFromCookie} from "./misc";
import {COUNTRY_CODE} from "../constants/store";
import logger from 'utils/logger';

interface ApiCallParams {
  url: string,
  method: "get" | "put" | "post" | "delete",
  data?: any,
  headers?: { [key: string]: string | undefined },
  ctx?: any,
  responseType?: ResponseType,
}

AxiosLogger.setGlobalConfig({
  prefixText: 'clout',
  dateFormat: 'HH:MM:ss',
  status: false,
  headers: true,
});


const axiosInstance = () => {
  const instance = axios.create();

  if (typeof window === 'undefined') {
    // instance.interceptors.request.use(AxiosLogger.requestLogger, AxiosLogger.errorLogger);
    // instance.interceptors.response.use(AxiosLogger.responseLogger, AxiosLogger.errorLogger);
    instance.interceptors.request.use((config) => {
        logger.info(`Axios Request - ${config.method?.toUpperCase()} ${config.url}`, {
          headers: config.headers,
          data: JSON.stringify(config.data),
        });
        return config;
      },
      (error) => {
        logger.error('Axios Request Error:', { error });
        return Promise.reject(error);
      });
    instance.interceptors.response.use((response) => {
        logger.info(`Axios Response - ${response.config.method?.toUpperCase()} ${response.config.url}`, {
          status: response.status,
          headers: response.headers,
          data: JSON.stringify(response.data),
        });
        return response;
      },
      (error) => {
        logger.error('Axios Response Error:', { error });
        return Promise.reject(error);
      });
  } else {
    instance.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error?.response?.status >= 500) {
        (async () => {
          await toast.error(error?.response?.data?.status || "Something went wrong");
        })();
      }

      // To avoid showing error toast message for TnC update alert since it is handled by <TnCUpdateAlert> component
      if (error?.response?.status === 428 && error?.response?.data?.status && error?.response?.data?.status.includes('Precondition Required')) {
        delete error.response.data.status;
      }
      // eslint-disable-next-line no-undef
      return Promise.reject(error);
    });
  }
  return instance;

}

// To check TnC update alert (428 - Precondition Required) is handled or not
let is428ErrorHandled = false;

export const apiCall = ({url, method, data, ctx, headers, responseType}: ApiCallParams) : Promise<AxiosResponse<any>> => {
  let responseType_ = responseType || 'json';
  let headers_: { [key: string]: string } = {
    'x-deviceid': ctx?.query.userid?.toString() || getCookie(ctx, COOKIES.deviceId) || '',
    'x-device-type': 'web',
    'x-web-nginx-request-id': ctx?.req?.headers['x-nginx-request-id']?.toString() || "",
    'x-auth-token': authToken(ctx),
    'x-session-id': sessionId(ctx),
    'business-type': 'shopifyDropshippingPlugin'
  };
  if (!headers_['x-auth-token']) {
    delete headers_['x-auth-token'];
  }
  if(!headers_['x-session-id']){
    delete headers_['x-session-id'];
  }
  if(getCountryPreferenceFromCookie(ctx)){
    headers_['Country-Code']=getCountryPreferenceFromCookie(ctx).code || COUNTRY_CODE.india
  }
  // if(url.includes('/saas/')){
  //   delete headers_['x-auth-token'];
  // }
  if (headers) {
    headers_ = Object.assign(headers_, headers)
  }

  return axiosInstance()({
    method,
    url,
    data,
    headers: headers_,
    responseType: responseType_,
  })
    .catch(e => {
      if (typeof window === 'undefined') {
        logger.error(e);
      } else {
        console.log(e);
      }
      if (e?.response?.status === 401) {
        if(url.includes("saas/auth/me")){
          throw e;
        }

        const userIsStaff = isStaff(ctx);
        deleteAllCookies(ctx);
        if(typeof window !== 'undefined'){
          if(userIsStaff){
            window.location.href = `/staff-login?redirect_uri=${encodeURIComponent(window.location.pathname)}`;
          }
          window.location.href = `/login?redirect_uri=${encodeURIComponent(window.location.pathname)}`;
        }
      }

      // To show the TnC update alert (428 - Precondition Required)
      if (e?.response?.status === 428 && !is428ErrorHandled && typeof window !== 'undefined' && !url?.includes('/tncStatus')) {
        is428ErrorHandled = true;
        window.dispatchEvent(new CustomEvent('tncUpdateAlert'));
      }

      throw e;
    });
}

export const paramsToString = (paramsObj: any) => {
  return new URLSearchParams(paramsObj).toString();
};

/** convert params to string with single key having multiple values. e.g., key=value1&key=value2&key=value2 */
export const iteratedParamsToString = (paramsObj: any) => {
  const params = new URLSearchParams();

  Object.entries(paramsObj).forEach(([key, value]: any[]) => {
    if (Array.isArray(value)) {
      value.forEach(val => { params.append(key, val); });
    } else {
      params.set(key, value);
    }
  });

  return params.toString();
};
