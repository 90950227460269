import {NextPageContext} from "next";
import { CountryCode, COUNTRY_CODE, PLATFORM_TYPE } from "constants/store";
import {CountryPreference, RedirectRoute, ManualProductTag} from "types";
import {COOKIES, getCookie, setCookie} from "./cookies";
import { paramsToString } from "./api";
import { pageName } from "constants/analytics";
import { PAGES_ENUM } from 'constants/route'
import UAParser from "ua-parser-js";

export const getDiscountPercentFromPrices = (originalPrice: number, discountPrice: number) => {
  return Math.round(100 * (1 - (discountPrice / originalPrice)))
}

export const slugify = (text: string) => !text ? text : text
  .toLowerCase()
  .replace(/[^\w ]+/g, '-')
  .replace(/ +/g, '-');

export const getState = (router: any) => {
  const params: any = {};
  if (router.query.code) {
    params.code = router.query.code;
  }
  if (router.query.state) {
    params.state = router.query.state;
  }
  if (router.query.hmac) {
    params.hmac = router.query.hmac;
  }
  if (router.query.host) {
    params.host = router.query.host;
  }
  if (router.query.shop) {
    params.shop = router.query.shop;
  }
  if (router.query.timestamp) {
    params.timestamp = router.query.timestamp;
  }
  if (router.query.response_type) {
    params.response_type = router.query.response_type;
  }
  if (router.query.client_id) {
    params.client_id = router.query.client_id;
  }
  if (router.query.scope) {
    params.scope = router.query.scope;
  }
  if (router.query.redirect_uri && isSafeUrl(router.query.redirect_uri)) {
    params.redirect_uri = router.query.redirect_uri;
  }
  if (router.query.registration_id) {
    params.registration_id = router.query.registration_id;
  }
  if (router.query.nonce) {
    params.client_id = router.query.client_id;
  }
  if (router.query.categoryId) {
    params.categoryId = router.query.categoryId;
  }
  return params;
}

// @ts-ignore
export const redirect = ({pathname, query}: RedirectRoute) => {
  window.location.href = `${pathname}${query ? `?${new URLSearchParams(query).toString()}` : ''}`
}

export const redirectUrlWithParams = ({pathname, query}: {pathname: string, query: any}) => {
  return `${pathname}${query ? `?${new URLSearchParams(query).toString()}` : ''}`;
}

export const redirectServerSide = async (ctx: NextPageContext, location: any) => {
  if (ctx.req) {
    ctx.res?.writeHead(302, {Location: location});
    ctx.res?.end();
  }
  return {props: {}};
}

export const loadScript = (src: string) => {
  new Promise((resolve: (value: any) => void) => {
    const scriptEl = document.createElement("script");

    scriptEl.src = src;
    scriptEl.async = true;
    scriptEl.onload = () => resolve(true);
    scriptEl.onerror = () => resolve(false);
    
    document.body.appendChild(scriptEl);
  });
}

export const getOrderPaymentModeText = (ugly: string) => {
  const paymentMode = ugly ? ugly.toLowerCase() : '';

  switch (paymentMode) {
    case 'cod':
      return 'COD';
    case 'onlinepayment':
      return 'Prepaid';
    default:
      return paymentMode;
  }
}

export function formatNumberInCommas(num: number | string) {
  if (!num) return num;

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getRupeeFormatted(num: number | string) {
  const value = typeof num === 'string' ? parseFloat(num) : num;

  if (value || value === 0) return `₹${formatNumberInCommas(Math.round(value))}`;
  
  return num;
}

export function getRupeeFormattedUpto2DecimalPlaces(num: number | string) {
  const value = typeof num === 'string' ? parseFloat(num) : num;

  if (value || value === 0) return `₹${formatNumberInCommas(Math.round(value*100)/100)}`;

  return num;
}

export const numberKFormatter = (num: number | string, fractionDigits?: number) => {
  if (!num) return num;

  num = typeof num === 'string' ? parseFloat(num) : num;

  // @ts-ignore
  return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(fractionDigits || 1)) + 'k' : Math.sign(num)*Math.abs(num);
}

export const removeCCodeFromProductName = (productName: string) => {
  const regex = /\s?\(Code:\s[C][A-Za-z0-9]+\)/;
  return productName?.replace(regex, '')?.trim() || productName;
}

export const roundOff = (number: number, decimalPlaces?: number) => {
  const factor = Math.pow(10, decimalPlaces || 0);
  return Math.round(number * factor) / factor;
};

export const checkCountryPreference = (selectedCountry: { id: number, code: string }, countryCode:CountryCode) => {
  if (selectedCountry?.id && selectedCountry?.code === COUNTRY_CODE.international && countryCode === COUNTRY_CODE.us) return true;

  return !!(selectedCountry?.id && selectedCountry?.code === countryCode);
}

export const getCountryPreferenceFromCookie = (ctx?: NextPageContext): CountryPreference => {
  return JSON.parse(getCookie(ctx, COOKIES.countryPreference) || '{}');
};

export const getCountInTens = (count: number) => {
  return count > 10 ? Math.floor((count || 0 )/ 10) * 10 : 0
}

export const setBodyScrollBehavior = (type: 'hidden' | 'default') => {
  if (typeof window === 'undefined') return;

  switch (type) {
    case 'hidden':
      document.body.classList.add('overflow-hidden', 'overscroll-contain');
      break;
    case 'default':
      document.body.classList.remove('overflow-hidden', 'overscroll-contain');
      break;
    default:
      break;
  }
};

export const priceDecimalFormatter = (price: any, countryCode: CountryCode = COUNTRY_CODE.india, isNumberType = true) => {
  if (!price) return price;

  switch (countryCode) {
    case COUNTRY_CODE.india:
      return isNumberType ? Number(parseFloat(price).toFixed(0)) : parseFloat(price).toFixed(0);
    case COUNTRY_CODE.us:
      return isNumberType ? Number(parseFloat(price).toFixed(2)) : parseFloat(price).toFixed(2);
    case COUNTRY_CODE.international:
      return isNumberType ? Number(parseFloat(price).toFixed(2)) : parseFloat(price).toFixed(2);
    default:
      return isNumberType ? Number(parseFloat(price).toFixed(0)) : parseFloat(price).toFixed(0);
  }
}

export const getCollectionVideoFeedPageUrl = (collectionId: number | string, rank: number | string) => {
  return `/video-feed/collection?${paramsToString({ 
    collectionId, 
    rank,
  })}`;
}

export const getSearchVideoFeedPageUrl = (productId: number, searchQueryParams: any, videoOffset?: number | string) => {
  let searchSuggestion;
  if (searchQueryParams?.type?.toString() === 'image') {
    searchSuggestion = {
      type: searchQueryParams?.type?.toString() || '',
    };
  } else {
    searchSuggestion = {
      suggestedText: searchQueryParams?.suggestedText?.toString() || '',
      subcategoryName: searchQueryParams?.subcategoryName?.toString() || '',
      mastercategoryName: searchQueryParams?.mastercategoryName?.toString() || '',
      categoryName: searchQueryParams?.categoryName?.toString() || '',
      isAutocorrect: searchQueryParams?.isAutocorrect?.toString() === 'true',
    };
  }

  return `/video-feed/search?${paramsToString({
    product: productId,
    ...searchSuggestion,
    ...((videoOffset || videoOffset === 0) && { videoOffset }),
  })}`;
}

export const isFromNativeApp = (ctx?: NextPageContext) => {
  return JSON.parse(getCookie(ctx, COOKIES.isFromNative) || 'false');
}

export const getWishlistVideoFeedPageUrl = (productId: number | string, rank: number | string) => {
  return `/video-feed/wishlist?${paramsToString({
    product: productId, 
    rank,
  })}`;
}

export const getCategoryVideoFeedPageUrl = (productId: number | string, searchQueryParams: any, videoOffset?: number | string) => {
  return `/video-feed/category?${paramsToString({
    product: productId,
    categoryId: searchQueryParams?.categoryId?.toString() || '',
    ...((videoOffset || videoOffset === 0) && { videoOffset }),
  })}`;
}

export const getPageNameFromUrl = (href: string) => {
  if (typeof window === undefined || !href) return '';

  let pgName = '';
  const url = new URL(href);

  pgName = pageName.find(page => {
    if (url.pathname === '/' && page.isHome) return true;
    return href.startsWith(`${window.location.origin}${page.url}`) && !page.isHome;
  })?.name || '';

  // Fallback page name
  if (!pgName) {
    const pathnameArr = url?.pathname?.split('/');
    const tempArr: string[] = [];

    if (!pathnameArr) return pgName;

    pathnameArr.forEach((value: any) => {
      const str = value.replace(/-./g, (match: any) => match.charAt(1).toUpperCase());
      if (str !== '' && isNaN(str) && isNaN(parseFloat(str))) tempArr.push(str);
    })

    pgName = tempArr.join('_');
  }

  return pgName;
}

export const getFormattedBucketStringFromArray = (orderTagArray?: number[], isSmScreen?: boolean) => {
  if (!orderTagArray || (orderTagArray[0] === 0 && orderTagArray[1] === 0)) {
    return `--`;
  } else if (orderTagArray[0] === 0 && orderTagArray[1] !== 0) {
    return `Upto ${isSmScreen ? numberKFormatter(orderTagArray[1]) : formatNumberInCommas(orderTagArray[1])}`;
  } else if (orderTagArray[1] === -1) {
    return `${isSmScreen ? numberKFormatter(orderTagArray[0]) : formatNumberInCommas(orderTagArray[0])}+`;
  } else {
    const formattedBucketString = `${formatNumberInCommas(orderTagArray[0])} - ${formatNumberInCommas(orderTagArray[1])}`;
    if (formattedBucketString.length > 10 || isSmScreen) {
      return `${numberKFormatter(orderTagArray[0])} - ${numberKFormatter(orderTagArray[1])}`;
    } else {
      return formattedBucketString;
    }
  }
}

export const checkDevicePlatform = (platform: PLATFORM_TYPE, ctx?: NextPageContext) => {
  return platform === getDevicePlatform(ctx);
};

export const setReportedVideosInLocalStorage = (valueId: number) => {
  if (typeof window === 'undefined') return;

  const reportedVideo = JSON.parse(localStorage.getItem('reportedVideo') || "[]");

  if (reportedVideo?.length && !reportedVideo.includes(valueId)) {
    localStorage.setItem('reportedVideo', JSON.stringify([...reportedVideo, valueId]));
  } else if (!reportedVideo?.length) {
    localStorage.setItem('reportedVideo', JSON.stringify([valueId]));
  }
}

export const checkForReportedVideo = (videoId: number) => {
  if (typeof window === 'undefined') return;

  const reportedVideo = JSON.parse(localStorage.getItem('reportedVideo') || "[]");

  return reportedVideo.includes(videoId);
}

export const getDevicePlatform = (ctx?: NextPageContext) => {
  const uaParser = new UAParser();
  const result = uaParser.getResult();
  const devicePlatform = getCookie(ctx, COOKIES.devicePlatform);

  return devicePlatform ? devicePlatform : (result?.device?.type || PLATFORM_TYPE.desktop);
}

export const getFormattedManualTagString = (tag: ManualProductTag) => {
  if (['facebook', 'cj'].includes(tag.type)) {
    return `${numberKFormatter(tag.count, 2)} ${tag.countSuffix}`;
  } else if (tag.type === 'aliexpress') {
    return `${numberKFormatter(tag.count, 2)}+ ${tag.countSuffix}`;
  }
  return `${numberKFormatter(tag.count, 2)} ${tag.countSuffix}`;
}

export const checkInvestorStoreNavAccess = (navId: string) => {
  const navIds = ['dashboard-analytics-nav-link', 'account-popup-nav-button', 'clout-updates-nav-link'];

  return navIds.includes(navId);
}

export const getAllowedEndpointsForRoute = (pathname: string): string[] => {
  if (pathname.startsWith(PAGES_ENUM.MANAGE_ORDER) || pathname.startsWith(PAGES_ENUM.MANAGE_NDR)) { return ["/orders/stores"]; }
  if (pathname.startsWith(PAGES_ENUM.COLLECTION)) { return ["/products"]; }
  if (pathname.startsWith(PAGES_ENUM.CATEGORY)) { return ["/supplyFeed/categories"]; }

  switch (pathname) {
    case PAGES_ENUM.HOME:
      return ["/collections"];
    case PAGES_ENUM.ANALYTICS:
      return ["/cloutDashboard", "/rcaDashboard"];
    case PAGES_ENUM.PUSHED_TO_SHOPIFY:
      return ["/productDetails"];
    case PAGES_ENUM.INVENTORY_REQUESTS:
      return ["/inventory/details"];
    case PAGES_ENUM.CATEGORY:
      return ["/categories"];
    case PAGES_ENUM.REPORTS:
      return ["/reportDetails"];
    case PAGES_ENUM.PAYMENTS:
      return ["/paymentDetails"];
    case PAGES_ENUM.WISHLIST:
      return ["/wishlist"]
    case PAGES_ENUM.WHATSAPP:
      return ["/clout/whatsapp"];
    case PAGES_ENUM.META:
      return ["/clout/fb"];
    case PAGES_ENUM.CLOUT_UPDATES:
      return ["/cloutPost"];
    case PAGES_ENUM.PROFILE:
      return ["/profile", "/bankAccountDetails", "/gstDetails"];
    case PAGES_ENUM.STORE_MANAGER:
      return ["/shopify-stores"];
    case PAGES_ENUM.STAFF_ACCESS_MANAGEMENT:
      return ["/auth/ownership"];
    default:
      return [];
  }
};

export const isSafeUrl = (url: string) => {
  const regex = /^javascript(:|%3A)/i;
  return !regex.test(url);
};

export const setPinnedStatesCookie = (pinnedStates: string[]) => {
  setCookie(null, COOKIES.pinnedStates, JSON.stringify(pinnedStates), null, 30)
}

export const getPinnedStatesCookie = () => {
  return JSON.parse(getCookie(null, COOKIES.pinnedStates) || "[]");
}

export const makeAuthTokenUrlFriendly = (key: string) => {
  const initialLength = key.length;
  const doubleEqualRemovedToken = key.substring(0, initialLength - 2);
  return doubleEqualRemovedToken.replace(/\+/g, "-").replace(/\//g, "_");
};

