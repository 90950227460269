import { apiCall, paramsToString } from 'utils/api';
import {sellerStoreId, userId} from 'utils/auth';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/clout`;

export const getWhatsappSubscriptionDetails = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/whatsapp/${sellerStoreId()}/subscriptionDetails`,
  })
  return res.data;
}

export const getWhatsappMessageStatistics = async (params: any) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/whatsapp/${sellerStoreId()}/messageStatistics?${paramsToString(params)}`,
  })
  return res.data;
}

export const getWhatsappMessageTriggers = async (params: any) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/whatsapp/${sellerStoreId()}/messageTriggers?${paramsToString(params)}`,
  })
  return res.data;
}

export const getWhatsappShippedOrdersStatistics = async (params: any) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/whatsapp/${sellerStoreId()}/shippedOrders?${paramsToString(params)}`,
  })
  return res.data;
}

export const updateWhatsappNotificationSettings = async (params: any) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/whatsapp/${sellerStoreId()}/notificationSettings/${userId()}?${paramsToString(params)}`,
  })
  return res.data;
}

export const getWhatsappNotificationSettings = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/whatsapp/${sellerStoreId()}/cooldownPeriod`,
  })
  return res.data;
}

export const getFacebookAppInfo = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/fb/appDetails`,
  })
  return res.data;
}

export const getLinkedFacebookAccounts = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/fb/adAccounts/${sellerStoreId()}`,
  })
  return res.data;
}

