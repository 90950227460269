export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const SET_PRODUCT_COLLECTIONS = 'SET_PRODUCT_COLLECTIONS';
export const SET_PRODUCT_FEED = 'SET_PRODUCT_FEED';
export const UPDATE_PRODUCT_FEED = 'UPDATE_PRODUCT_FEED';
export const UPDATE_PRODUCT_COLLECTION = 'UPDATE_PRODUCT_COLLECTION';
export const UPDATE_PRODUCT_COLLECTION_PRODUCTS = 'UPDATE_PRODUCT_COLLECTION_PRODUCTS';

export const SET_RTO_PROBABLITY_FAQS = 'SET_RTO_PROBABLITY_FAQS';
export const SET_RTO_FILTER_REASONS = 'SET_RTO_FILTER_REASONS';

export const SET_HAS_UNREAD_CLOUT_POST = 'SET_HAS_UNREAD_CLOUT_POST';

export const SET_STORE_ORDERS = 'SET_STORE_ORDERS';
export const UPDATE_STORE_ORDERS = 'UPDATE_STORE_ORDERS';
export const REST_STORE_ORDERS = 'REST_STORE_ORDERS';

export const SET_FAILED_TO_SYNC_REASONS = 'SET_FAILED_TO_SYNC_REASONS';

export const SET_NDR = 'SET_NDR';
export const UPDATE_NDR = 'UPDATE_NDR';
export const REST_NDR = 'REST_NDR';

export const GET_RETURN_ORDER_REASONS = 'RETURN_ORDER_REASONS';
export const GET_CANCEL_RETURN_REASONS = 'CANCEL_RETURN_REASONS';

export const SET_IN_LOCAL_STORAGE = 'SET_IN_LOCAL_STORAGE';
export const REMOVE_FROM_LOCAL_STORAGE = 'REMOVE_FROM_LOCAL_STORAGE';
export const CLEAR_LOCAL_STORAGE = 'CLEAR_LOCAL_STORAGE';

export const UPDATE_DASHBOARD_SETTINGS = 'UPDATE_DASHBOARD_SETTINGS';

export const UPDATE_USER_COUNTRY_PREFERENCE = 'UPDATE_USER_COUNTRY_PREFERENCE';

export const SET_IN_COOKIE = 'SET_IN_COOKIE';
export const DELETE_FROM_COOKIE = 'DELETE_FROM_COOKIE';
export const UPDATE_DRAWER = 'UPDATE_DRAWER';

export const SET_PORTRAIT_VIDEO_PLAYER = 'SET_PORTRAIT_VIDEO_PLAYER';
export const UPDATE_PORTRAIT_VIDEO_PLAYER = 'UPDATE_PORTRAIT_VIDEO_PLAYER';
export const UPDATE_PORTRAIT_VIDEO_PLAYER_PRODUCT = 'UPDATE_PORTRAIT_VIDEO_PLAYER_PRODUCT';

export const UPDATE_NOTIFICATION_DATA = 'UPDATE_NOTIFICATION_DATA';
export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';

export const UPDATE_NOTIFICATION_INFO = 'UPDATE_NOTIFICATION_INFO';

export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export const SET_NOTIFICATION_IS_UNREAD_ONLY = 'SET_NOTIFICATION_IS_UNREAD_ONLY';
export const UPDATE_NOTIFICATION_DETAILS = 'UPDATE_NOTIFICATION_DETAILS';

export const SET_WISHLIST = 'SET_WISHLIST';
export const UPDATE_WISHLIST = 'UPDATE_WISHLIST';
export const UPDATE_WISHLIST_PRODUCT = 'UPDATE_WISHLIST_PRODUCT';
export const RESET_WISHLIST = 'RESET_WISHLIST';
export const SET_WHATSAPP_SUBSCRIPTION = 'SET_WHATSAPP_SUBSCRIPTION';
export const SET_META_ACCOUNT_DETAILS = 'SET_META_ACCOUNT_DETAILS';
export const UPDATE_PDP_ACTIVE_NAV = 'UPDATE_PDP_ACTIVE_NAV';

export const SET_IMAGE_SEARCH_DATA = 'SET_IMAGE_SEARCH_DATA';
