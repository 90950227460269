import {ReduxAction, SET_SHOPIFY_STORE_DATA, SET_SHOPIFY_STORE_ERROR} from '../types';
import { SET_HAS_UNREAD_CLOUT_POST, UPDATE_USER_COUNTRY_PREFERENCE, SET_WHATSAPP_SUBSCRIPTION, SET_META_ACCOUNT_DETAILS, SET_IMAGE_SEARCH_DATA, UPDATE_PDP_ACTIVE_NAV } from 'actions/types';

const initialState = {
  shopifyStoreData: {},
  storesFetched: false,
  error: {},
  hasUnreadCloutPost: false,
  selectedCountry: {},
  whatsappSubscriptionDetails: {},
  metaAccountDetails: [],
  imageSearchData: {
    productDetails: [],
    productMetaData: {},
    visenzeProductData: []
  },
  pdpActiveNav: '',
}

export default function (state = initialState, action: ReduxAction) {
  switch (action.type) {
    case SET_SHOPIFY_STORE_DATA:
      return {
        ...state,
        shopifyStoreData: action.payload,
        storesFetched: true

      }
    case SET_SHOPIFY_STORE_ERROR:
      return {
        storesFetched: false,
        error: action.payload
      }
    case SET_HAS_UNREAD_CLOUT_POST: {
      return ({
        ...state,
        hasUnreadCloutPost: action.payload
      });
    }
    case UPDATE_USER_COUNTRY_PREFERENCE:
      return {
        ...state,
        selectedCountry: { ...state.selectedCountry, ...action.payload },
      }
    case SET_WHATSAPP_SUBSCRIPTION:
      return {
        ...state,
        whatsappSubscriptionDetails: { ...state.whatsappSubscriptionDetails, ...action.payload },
      }
    case SET_META_ACCOUNT_DETAILS:
      return {
        ...state,
        metaAccountDetails: action.payload,
      }
    case SET_IMAGE_SEARCH_DATA:
      return {
        ...state,
        imageSearchData: action.payload
      }
    case UPDATE_PDP_ACTIVE_NAV: 
      return {
        ...state,
        pdpActiveNav: action.payload
      }
    default:
      return state
  }
}